
<script>
import DatePicker from 'vue2-datepicker';
import { mapGetters, mapMutations } from 'vuex';
import stepsMixin from '@/mixins/stepsMixin'
export default {
	mixins: [stepsMixin],
	props: ['client'],
	components: {
		SvgIcon: () => import('@/components/SvgIcon'),
		DatePicker
	},
	data() {
		return {
			name: 'evaluation_arce',
			form: {
				comentariosValoracionGeneral: '',
				clienteVisitado12Meses: '',
				asistentes: '',
				comentariosSobreVisita: '',
				fechaVisita: '',
			},
		}
	},
	watch: {
		'client': {
			handler(nVal) {
				this.fillForm()
			},
			deep: true,
			immediate: true,
		},
	},
	methods: {

		calcFill() {
			let fields = Object.keys(this.form)


			if(this.form.clienteVisitado12Meses == 'NO') {
				this.form.asistentes = ''
				let index = fields.indexOf('asistentes')
				fields.splice(index, 1)
				this.form.comentariosSobreVisita = ''
				index = fields.indexOf('comentariosSobreVisita')
				fields.splice(index, 1)
				this.form.fechaVisita = ''
				index = fields.indexOf('fechaVisita')
				fields.splice(index, 1)
			}

			let points = 100 / fields.length
			let fill = 0
			fields.forEach(f => {
				if(this.form[f]) {
					fill += points
				}
			})

			return Math.round(fill)
		},
		
		fillForm() {
			let data = {}
			if(this.client.arce.hasOwnProperty(this.name)) {
				data = {...this.client.arce[this.name]}
			}

			if(!Object.keys(data).length) {
				this.$set(this, 'form', {
					comentariosValoracionGeneral: '',
					clienteVisitado12Meses: '',
					asistentes: '',
					comentariosSobreVisita: '',
					fechaVisita: '',
				})
				return 
			}


			this.$set(this, 'form', Object.assign({
					comentariosValoracionGeneral: '',
					clienteVisitado12Meses: '',
					asistentes: '',
					comentariosSobreVisita: '',
					fechaVisita: '',
				}, {...data}))
		},
		save() {
			let vm = this
			let payload =  {
				client:  this.client,
				form: this.name,
				fill: vm.calcFill(),
				data: Object.assign({...this.form}),
			}
			vm._setDataChild(payload)
			vm.$emit('updateArce')
		},

	
		sumTotal(item, key) {
			if(!item.length) {
				return 0
			}
			let sum = item.reduce((a, c) => {
				let obj = {}
				obj[key] = Number(a[key]) + Number(c[key])
				return obj
			})

			return sum[key]
		},
	
		showModal(id) {
			if(document.querySelectorAll(`${id}`).length > 1) {
				$(`body > ${id}`).remove()
				this.showModal(id)
			} else {
				$(id).appendTo("body").modal('show')

			}
		},
		closeModal() {
			this.hiddenSteps()
			this.showSteps('stepsPolicies')
			this.fillForm()
		},
		...mapMutations({
			_setDataChild: 'arceDetails/setDataChild'
		})
	},

	mounted() {
		$('#formEvaluationModal').on('hide.bs.modal', this.closeModal)
	},

	beforeDestroy() {
		$('#formEvaluationModal').off('hide.bs.modal', this.closeModal)
	}
}
</script>
<template>
	<div style="">
		<div class="text-right py-2">
			<button class="s-btn s-btn--primary" @click="showModal('#formEvaluationModal')">
				Editar
			</button>
		</div>
		<div class="">
			<div class="s-collapse__header" data-toggle="collapse" data-target="#c1_fe1" aria-expanded="true" aria-controls="c1_fe1">
				<div class="d-flex">
					<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span> Valoración General</span></h6>
				</div>
			</div>
			<div id="c1_fe1" class="collapse show" aria-labelledby="headingOne">
				<div class="d-flex flex-wrap mx-n2">
					<div class="col-4">
						<label class="d-block m-0">
							<p class="m-0"><span class="text-danger">*</span> Comentarios</p>
							<textarea class="form-control form-control--disabled" disabled type="text" v-model="form.comentariosValoracionGeneral">
							</textarea>
						</label>
					</div>
				</div>
			</div>
			<div class="s-collapse__header collapsed  mt-2" data-toggle="collapse" data-target="#c1_m_fe2" aria-expanded="true" aria-controls="c1_m_fe2">
				<div class="d-flex">
					<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span> Reuniones con los clientes</span></h6>
				</div>
			</div>
			<div id="c1_m_fe2" class="collapse" aria-labelledby="headingOne">
				<div class="d-flex py-2 flex-wrap">
					<div class="col-4 p-2">
						<label class="d-block">
							<p class="m-0"><span class="text-danger">*</span> El cliente ha sido visitado en los últimos 12 meses</p>
							<input class="form-control form-control--disabled" disabled type="text" v-model="form.clienteVisitado12Meses">
						</label>
					</div>
				
					<div class="col-4 p-2" v-if="form.clienteVisitado12Meses == 'SI'">
						<span class="d-block">
							<p class="m-0"><span class="text-danger">*</span> Fecha</p>
							<input class="form-control form-control--disabled" disabled type="text" v-model="form.fechaVisita">
						</span>
					</div>
					<div class="col-4 p-2"  v-if="form.clienteVisitado12Meses == 'SI'">
						<label class="d-block m-0">
							<p class="m-0"><span class="text-danger">*</span> Asistentes</p>
							<select class="form-control form-control--disabled" disabled type="text" v-model="form.asistentes">
								<option value=""></option>
								<option value="Manejador">Manejador</option>
								<option value="Analista">Analista</option>
								<option value="Ambos">Ambos</option>
							</select>
						</label>
					</div>
					<div class="col-4 p-2" v-if="form.clienteVisitado12Meses == 'SI'">
						<label class="d-block m-0">
							<p class="m-0"><span class="text-danger">*</span> Comentarios sobre la visita</p>
							<textarea class="form-control form-control--disabled" disabled type="text" v-model="form.comentariosSobreVisita">
							</textarea>
						</label>
					</div>
				</div>
			</div>
		</div>

		<!-- ============================================ -->
		<!-- ============================================ -->
		<!-- ============================================ -->
		<!-- ============================================ -->
		<!-- ============================================ -->
		<div class="modal s-modal fade" style="font-size: 0.8em" id="formEvaluationModal" tabindex="-1" role="dialog" aria-labelledby="formEvaluationModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered modal-xl " role="document">
				<div class="modal-content">
				<div class="modal-header p-0">
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div class="">
						<div class="s-collapse__header" data-toggle="collapse" data-target="#c1_m_fe1" aria-expanded="true" aria-controls="c1_m_fe1">
							<div class="d-flex">
								<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span> Valoración General</span></h6>
							</div>
						</div>
						<div id="c1_m_fe1" class="collapse show" aria-labelledby="headingOne">
							<div class="d-flex flex-wrap mx-n2">
								<div class="col-4">
									<label class="d-block m-0">
										<p class="m-0"><span class="text-danger">*</span> Comentarios</p>
										<textarea class="form-control" type="text" v-model="form.comentariosValoracionGeneral">
										</textarea>
										<characters-available :max="1000" :text="form.comentariosValoracionGeneral"></characters-available>

									</label>
								</div>
							</div>
						</div>
						<div class="s-collapse__header collapsed  mt-2" data-toggle="collapse" data-target="#c1_m_fe2" aria-expanded="true" aria-controls="c1_m_fe2">
							<div class="d-flex">
								<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span> Reuniones con los clientes</span></h6>
							</div>
						</div>
						<div id="c1_m_fe2" class="collapse" aria-labelledby="headingOne">
							<div class="d-flex py-2 flex-wrap">
								<div class="col-4 p-2">
									<label class="d-block">
										<p class="m-0"><span class="text-danger">*</span> El cliente ha sido visitado en los últimos 12 meses</p>
										<div class="s-tf">
											<label class="s-tf__item">
												<input type="radio" name="fe_cv12m" value="SI" v-model="form.clienteVisitado12Meses">
												<span class="s-tf__btn">SI</span>
											</label>
											<label class="s-tf__item" >
												<input type="radio" name="fe_cv12m" value="NO" v-model="form.clienteVisitado12Meses">
												<span class="s-tf__btn">NO</span>
											</label>
										</div>
									</label>
								</div>
							
								<div class="col-4 p-2" v-if="form.clienteVisitado12Meses == 'SI'">
									<span class="d-block">
										<p class="m-0"><span class="text-danger">*</span> Fecha</p>
										<date-picker style="width: 100%" v-model="form.fechaVisita" valueType="format"></date-picker>
									</span>
								</div>
								<div class="col-4 p-2"  v-if="form.clienteVisitado12Meses == 'SI'">
									<label class="d-block m-0">
										<p class="m-0"><span class="text-danger">*</span> Asistentes</p>
										<select class="form-control" type="text" v-model="form.asistentes">
											<option value=""></option>
											<option value="Manejador">Manejador</option>
											<option value="Analista">Analista</option>
											<option value="Ambos">Ambos</option>
										</select>
									</label>
								</div>
								<div class="col-4 p-2" v-if="form.clienteVisitado12Meses == 'SI'">
									<label class="d-block m-0">
										<p class="m-0"><span class="text-danger">*</span> Comentarios sobre la visita</p>
										<textarea class="form-control" type="text" v-model="form.comentariosSobreVisita">
										</textarea>
										<characters-available :max="150" :text="form.comentariosSobreVisita"></characters-available>

									</label>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="s-btn s-btn--outline" data-dismiss="modal">Cancelar</button>
					<button type="button" class="s-btn s-btn--primary" data-dismiss="modal" @click="save">Guardar</button>
				</div>
				</div>
			</div>
		</div>
	</div>
</template>
